/* client/src/App.css */
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background: #121212;
  color: #e0e0e0;
}

.waveform {
  margin: 15px 0;
  border: 1px solid #424242;
  border-radius: 4px;
  height: 150px;
  background: #1e1e1e;
}

a {
  color: #90caf9;
  text-decoration: none;
}

a:hover {
  color: #64b5f6;
}

/* Ensure text inputs and labels are visible in dark theme */
input, textarea, select {
  color: #e0e0e0 !important;
  background-color: #1e1e1e !important;
  border-color: #424242 !important;
}

input::placeholder, textarea::placeholder {
  color: #757575 !important;
}

label {
  color: #e0e0e0 !important;
}

.MuiInputBase-input, .MuiSelect-select {
  color: #e0e0e0 !important;
}

.MuiInputLabel-root {
  color: #e0e0e0 !important;
}

.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: #424242 !important;
}

/* Make help text lighter */
.MuiFormHelperText-root {
  color: #b0b0b0 !important; /* Lighter gray for better readability */
}